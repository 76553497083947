<template>
  <div class="con" v-if="!$store.state.pcshow">
    <img src="../../assets/img/index/companyIntro16.png" alt="" />
    <div class="text">
      <div class="title">我国医疗器械监管法规体系的构建、政策及发展建议</div>
      <div class="time">更新于2022-03-01</div>
      <p>医疗器械产业属于知识密集型科技制造领域，产品研发与生产涉及机械、电子、高分子材料等多个专业，其复杂性、多样性对监管工作的规范性与科,高分子材料等多个专业，其复杂性、多样性对监管学性提出了越来越高的要求。</p>
      <p>医疗器械产业属于知识密集型科技制造领域，产品研发与生产涉及机械、电子、高分子材料等多个专业，其复杂性、多样性对监管工作的规范性与科,高分子材料等多个专业，其复杂性、多样性对监管学性提出了越来越高的要求。</p>
      <img src="../../assets/img/information/img(8).png" alt="">
      <p>医疗器械产业属于知识密集型科技制造领域，产品研发与生产涉及机械、电子、高分子材料等多个专业，其复杂性、多样性对监管工作的规范性与科,高分子材料等多个专业，其复杂性、多样性对监管学性提出了越来越高的要求。</p>
      <p>医疗器械产业属于知识密集型科技制造领域，产品研发与生产涉及机械、电子、高分子材料等多个专业，其复杂性、多样性对监管工作的规范性与科,高分子材料等多个专业，其复杂性、多样性对监管学性提出了越来越高的要求。</p>
    </div>
  </div>
  <!-- 企业资讯二级 -->
  <div class="main" v-else>
    <!-- 横幅图 -->
    <img src="../../assets/img/information/img(1).png" alt="" />
    <div class="mainContent">
      <div class="currentPosition">
        <div class="curPosition">
          <div class="currentTop">
            <div class="bread">
              <span>当前位置</span>
              <span>></span>
              <span
                :class="
                  $route.path === '/companyInfoItem'
                    ? 'activeColor'
                    : 'defaultColor'
                "
                >企业资讯</span
              >
              <span>></span>
              <span>详情</span>
              <span>></span>
            </div>
            <div class="title">以医为本，跨界融合，创新发展</div>
          </div>
          <div class="currentBottom">2018-12-25</div>
        </div>
      </div>
      <div class="infoBox">
        <ul>
          <li>
            <h4>首届医+论坛暨医疗健康国际合作分会授牌仪式在沪召开 </h4>
            <img src="../../assets/img/information/img(8).png" alt="" />
            <p>
              2018年12月23日上午，首届医+论坛在上海奉贤开幕。本届论坛由商务部中国欧洲经济技术合作协会医疗健康国际合作分会主办，北京永大税务师事务所有限公司协办，上海亿联体创业孵化器管理有限公司承办。
            </p>
          </li>
          <li>
            <img src="../../assets/img/information/img(9).png" alt="" />
            <h4> 原国家卫生部副部长 张凤楼</h4>
          </li>
          <li>
            <img src="../../assets/img/information/img(10).png" alt="" />
            <h4> 原国家税务总局副局长 许善达 </h4>
          </li>
          <li>
            <img src="../../assets/img/information/img(11).png" alt="" />
            <h4>
               中国高科技产业化研究会副理事长、国家信息中心国家课题组成员 刘延宁 
            </h4>
          </li>
          <li>
            <p>
              原国家卫生部副部长张凤楼先生就“新形势下中国医疗健康产业的转型升级”做了主题发言，原国家税务总局副局长许善达先生重点阐述了“中国当前经济形势和税制改革的关键要点”，中国高科技产业化研究会副理事长、国家信息中心国家课题组成员刘延宁女士为“探索医疗科技成果市场化产业转化创新模式”指明了方向。
            </p>
            <img src="../../assets/img/information/img(12).png" alt="" />
            <h4>左一为商务部中国欧洲经济技术合作协会副会长代军杨 </h4>
            <p>
              商务部中国欧洲经济技术合作协会副会长代军杨先生为医疗健康国际合作分会授牌，并对分会在“促进医疗产业与现代服务业的深度融合”方面做了重要指示。分会理事长、上海亿联体创业孵化器管理有限公司董事长张金培先生表示，将发挥好分会在“走出去、引进来”，搭建国际交流合作平台，促进产业化发展中的媒介作用。
            </p>
            <p>
              与会嘉宾参观了上海奉贤东方美谷和亿联体总部，有合作意向的双方进行了一对一的深入沟通。通过本次大会的召开，有力推动了政府、机构、企业的沟通对话，共商共谋未来“医+”事业发展大计。
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px) {
    .con{
      width: 100%;
      background: #F5F5F5;
      
      >img{
        display: block
        width:7.5rem;
        height:3rem;
      }
      .text{
        padding:0 0.34rem;
        padding-bottom: 0.1rem;

        .title{
          font-size: 0.3rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 0.5rem;
          margin-top: 0.3rem;
          margin-bottom: 0.24rem;
        }
        .time{
          font-size: 0.26rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          line-height: 0.5rem;
          margin-bottom: 0.12rem;
        }
        p{
          font-size: 0.26rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          line-height: 0.5rem;
          padding-bottom: 0.2rem;
        }
        img{
          display: block;
          width: 6.8rem;
          height: 5.1rem;
          margin: 0 auto;
          margin-bottom: 0.2rem;
        }
      }
    }
  }
  @media screen and (min-width: 960px) { 
    .main {
      margin-top: 100px;
      font-family: Source Han Sans CN;
      color: #333333;

      >img {
        width: 100%;
      }

      .mainContent {
        width: 1280px;
        margin: 0 auto;

        .currentPosition {
          margin-bottom: 30px;

          .curPosition {
            border-bottom: 1px solid #EAEAEA;

            .currentTop {
              margin: 30px auto;
              display: flex;
              justify-content: space-between;

              .bread {
                span {
                  margin-right: 10px;
                  color: #666
                }
                .activeColor {
                  color: #0085D0
                }
              }

              .title {
                width: 450px;
                margin: 0 auto;
                // margin-left: 290px;
              }
            }

            .currentBottom {
              margin-left: 610px;
              font-size: 16px;
              font-weight: 400;
              color: #999;
              line-height: 39px;
            }
          }
        }

        .infoBox {
          margin-bottom: 120px;

          ul {
            text-align: center;

            li {
              h4 {
                height: 14px;
                line-height: 39px;
                font-size: 14px;
                font-weight: 500;
                color: #0085D0;
                margin: 0 0 24px;
              }

              p {
                margin: 30px 0 32px;
                text-align: left;
                width: 1273px;
                height: 38px;
                font-size: 14px;
                font-weight: 400;
                line-height: 25px;
              }
            }
          }
        }
      }
    }
  }
</style>